<template>
  <Tooltip
    :content="content"
    :theme="theme"
    :placement="placementPosition"
    ref="tooltip"
  >
    <slot name="label"></slot>
    <slot name="content" slot="content"></slot>
  </Tooltip>
</template>
<script>
export default {
  props: ["content", "theme"],
  mounted() {
    this.placementPosition = this.getPlacementPosition();
  },
  data() {
    return {
      placementPosition: this.getPlacementPosition(),
    };
  },
  methods: {
    getPlacementPosition() {
      if (this.$refs.tooltip) {
        if (this.$refs.tooltip.$el.offsetLeft > this.windowWidth / 2) {
          return "bottom-end";
        }
      }
      return "bottom-start";
    },
  },
  watch: {
    windowWidth() {
      this.placementPosition = this.getPlacementPosition();
    },
  },
};
</script>
