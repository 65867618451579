var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"field",style:({
      'text-align': 'left',
      width: _vm.labelWidth ? ("" + _vm.labelWidth) : '0px',
    })},[_c('span',{class:_vm.requiredClass,staticStyle:{"margin":"auto 0px"}},[_vm._v(_vm._s(_vm.field))])]),_vm._v(" "),_c('div',{style:({
      margin: 'auto',
      width: ("calc( 100% - " + (_vm.labelWidth || '0px') + " )"),
      display: 'flex',
    })},_vm._l((_vm.selections),function(selection){return _c('radio-button',{key:selection.tag,staticClass:"py-0 px-2",attrs:{"group":_vm.group,"tag":selection.tag,"text":selection.text,"noImg":true,"disabled":_vm.disabled,"value":_vm.value},on:{"input":function($event){return _vm.update($event)}}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }