<template>
  <div class="border-radius p-2 colorMain">
    <div v-for="(row, index) in record.mix_constitution" :key="index">
      <DividerText v-if="index" :dash="true" />
      <label class="subTitle bold" v-html="row.title"> </label>
      <div v-for="(content, index) in row.content" :key="index">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
};
</script>