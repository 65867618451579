<template>
 <!-- 問券內容頁面 -->
  <div class="bgMain" style="">
    <div
      class="bgWhite row"
      style="position: fixed; width: inherit; max-width: inherit; z-index: 1"
    >
      <Progress
        :percent="percent"
        hide-info
        class="mx-3 w-0 flex-grow-1"
        :stroke-color="['#108ee9', '#108ee9']"
      /><span class="mx-3" style="text-align: center"
        >{{ page }}/{{ totalPage }}</span
      >
    </div>
    <transition name="slide" mode="out-in" class="mx-0">
      <div v-show="showPage">
        <div class="pt-4 px-1">
          <div class="row mx-0">
            <div class="description col-6 px-0">
              {{ $t("question_select_none") }}：{{ $t("question_hint_none") }}
            </div>
            <div class="description col-6 px-0">
              {{ $t("question_select_seldom") }}：{{
                $t("question_hint_seldom")
              }}
            </div>
          </div>
          <div class="row mx-0">
            <div class="description col-6 px-0">
              {{ $t("question_select_sometimes") }}：{{
                $t("question_none_sometimes")
              }}
            </div>
            <div class="description col-6 px-0">
              {{ $t("question_select_often") }}：{{ $t("question_none_often") }}
            </div>
          </div>
          <div
            v-for="question in pageQuestions"
            :key="question.id"
            class="bgWhite my-2 p-2"
            :style="question.red ? 'border:1px red solid' : ''"
          >
            <div class="row mx-0">
              <div class="col-1 px-0 row mx-0 pt-2 justify-content-center">
                <label class="circle">&nbsp;</label>
              </div>
              <div class="col-11 px-0">
                <div style="width: 100%">{{ question.title }}</div>
                <div v-if="question.selection_count == 4" class="row mx-0 pt-3">
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="0"
                    :text="$t('question_select_none')"
                    class="mr-2"
                  />
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="1"
                    :text="$t('question_select_seldom')"
                    class="mx-2"
                  />
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="2"
                    :text="$t('question_select_sometimes')"
                    class="mx-2"
                  />
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="3"
                    :text="$t('question_select_often')"
                    class="mx-2"
                  />
                </div>
                <div v-if="question.selection_count == 2" class="row mx-0 pt-3">
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="1"
                    :text="$t('question_select_yes')"
                    class="mr-2"
                  />
                  <radio-button
                    v-model="answers[question.id]"
                    :group="`question-${question.id}`"
                    tag="0"
                    :text="$t('question_select_no')"
                    class="mx-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-5 row mx-0">
          <div
            v-if="page != 1"
            class="button1 mx-1 flex-grow-1 w-0"
            @click="goPrev()"
          >
            {{ $t("question_button_prev") }}
          </div>
          <div
            v-if="page != totalPage"
            class="button1 mx-1 flex-grow-1 w-0"
            @click="goNext()"
          >
            {{ $t("question_button_next") }}
          </div>
          <div
            v-if="page == totalPage"
            class="button1 mx-1 flex-grow-1 w-0"
            @click="commit()"
          >
            {{ $t("common_button_complete") }}
          </div>
        </div>
        <div style="visibility: hidden" onclick="void(0)">
          for first click no use
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import RadioButton from "../component/RadioButton.vue";
export default {
  components: { RadioButton },
  mounted() {
    loading();
    const source = this.$route.params.source;
    UserInfoApiHelper.getQuestion({ source })
      .then((data) => {
        loading(false);
        const answers = {};
        this.questions = data.questions.map((q) => {
          answers[q.id] = this.answers[q.id] >= 0 ? this.answers[q.id] : -1;
          q.red = false;
          return q;
        });
        this.answers = answers;
        this.totalPage = data.totalPage;
      })
      .catch(errorHandler);
  },
  data() {
    const answers = getCookie("answers");
    return {
      showPage: true,
      totalPage: 0,
      questions: [],
      answers: answers || {},
    };
  },
  computed: {
    pageQuestions() {
      return this.questions.filter((q) => q.page == this.page - 1);
    },
    percent() {
      if (this.totalPage > 0) {
        return (this.page / this.totalPage) * 100;
      } else {
        return 0;
      }
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    checkAnswer() {
      const pageCount = this.pageQuestions.length;
      const notfinish = [];
      for (let i = 0; i < pageCount; i++) {
        const qid = this.pageQuestions[i].id;
        if (this.answers[qid] < 0) {
          notfinish.push(this.getIndex(i) + 1);
        }
        this.pageQuestions[i].red = this.answers[qid] < 0;
      }
      return notfinish;
    },
    goNext() {
      const notfinish = this.checkAnswer();
      if (notfinish.length == 0) {
        this.$router.replace({
          name: "Question",
          query: { page: this.page + 1 },
        });
      } else {
        alert(this.$t("question_some_question_not_complete"));
      }
    },
    goPrev() {
      this.$router.replace({
        name: "Question",
        query: { page: this.page - 1 },
      });
    },
    commit() {
      const notfinish = this.checkAnswer();
      if (notfinish.length != 0) {
        alert(this.$t("question_some_question_not_complete"));
        return;
      }
      loading();
      const source = this.$route.params.source;
      const answers = Object.keys(this.answers).map(
        (key) => `${key}:${this.answers[key]}`
      );
      UserInfoApiHelper.submitAnswer({
        answers: answers.join(","),
        source,
      })
        .then(() => {
          setCookie("answers", "");
          loading(false);
          this.$router.replace({ name: "Result" });
        })
        .catch(errorHandler);
    },
    getIndex(index) {
      let prevCount = 0;
      for (let i = 0; i < this.page - 1; i++) {
        prevCount += this.questions.filter((q) => q.page == i).length;
      }
      return prevCount + index;
    },
    getQNum(index) {
      const num = this.getIndex(index) + 1;
      return num < 10 ? `0${num}` : num;
    },
  },
  watch: {
    answers: {
      handler: (value) => {
        setCookie("answers", value);
      },
      deep: true,
    },
    "$route.query.page": function (value) {
      window.scrollTo(0, 0);
      this.showPage = false;
      setTimeout(() => {
        this.showPage = true;
      }, 400);
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}
.slide-leave-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
</style>