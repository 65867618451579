<template>
  <div class="border-radius p-3 colorMain" slot="content">
    <div v-for="(disease, index) in record.hid_sick" :key="index" class="py-2">
      <div class="subTitle bold">
        {{ disease.disease_name }}
      </div>
      <div>
        {{ disease.description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  computed: {},
};
</script>