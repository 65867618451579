<template>
 <!-- 聯絡客服頁面 -->
  <div>
    <div
      class="content colorMain"
      style="position: relative; text-align: center"
    >
      <div class="py-3 title">{{ $t("concat_mail") }}</div>
      <div class="py-5">
        <div class="py-3">services@louyiai.com</div>
        <div class="py-3">{{ $t("concat_time") }} 9:00-18:00</div>
      </div>
      <img src="/img/email.png" style="width: 27vmin; max-width: 150px" />
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  beforeDestroy() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
