const md5 = require('md5');
const common = {
    setCookie(cname, cvalue) {
        switch (typeof cvalue) {
            case "object":
                cvalue = JSON.stringify(cvalue);
                break;
        }
        var exdays = 3;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                let target = c.substring(name.length, c.length);
                try {
                    target = JSON.parse(target);
                } catch (error) {
                }
                return target;
            }
        }
        return "";
    },
    setLocalStorage(key, item) {
         switch (typeof item) {
            case "object":
                item = JSON.stringify(item);
                break;
        }
        localStorage.setItem(key, item);
    },
    getLocalStorage(key) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
         } catch (e) {
            return value;
        }
    },
    bindProcess(process) {
        this.process = process;
    },
    log(text) {
        if (process.env.MIX_APP_DEBUG == "true") {
            console.debug(text);
        }
        //  console.debug(text);
    },
    getQueryUrl(key) {
        const params = {};
        location.search.split(/[?&]/).filter((item) => item).forEach((item) => {
            const splitItem = item.split('=');
            params[splitItem[0]] = splitItem[1];
        });
        return params[key] ? decodeURIComponent(params[key]) : null;
    },
    isWebview() {
        var useragent = navigator.userAgent;
        var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(wv|\.0\.0\.0)'];
        var regex = new RegExp(`(${rules.join('|')})`, 'ig');
        return Boolean(useragent.match(regex));
    },
    md5(s) {
        return md5(`louyi${s}console`);
    },
    compare(obj1, obj2) {
        let isSame = true;
        if (obj1 && obj2 && typeof obj1 == 'object' && typeof obj2 == 'object') {
            const keys = Object.keys(obj1);
            const objectKeys = Object.keys(obj2);
            keys.find((key) => {
                if (objectKeys.indexOf(key) >= 0) {
                    const type = typeof obj1[key];
                    if (type == 'object') {
                        isSame = compare(obj1[key], obj2[key]);
                    } else {
                        isSame = obj1[key] == obj2[key];
                    }
                } else {
                    isSame = false;
                }
                return !isSame;
            });
        }else {
            isSame = obj1 == obj2
        }
        
        return isSame;
    },
    copy(object) {
        if (object !== null && object !== undefined) {
            if (object instanceof Array) {
                const newArray = [];
                object.forEach((item) => {
                    newArray.push(copy(item));
                })
                return newArray;
            } else if (typeof object == "object") {
                const newObj = {};
                Object.keys(object).forEach((key) => {
                    newObj[key] = copy(object[key]);
                });
                return newObj;
            }
        }
        return object;
        
    },
    copyTo(object, toObject = {}) {
        if (typeof object == "object") {
            Object.keys(object).forEach((key) => {
                toObject[key] = copyTo(object[key], toObject[key]);
            });
            return toObject;
        } else {
            return object;
        }
    }
}
Object.assign(window, common);

Date.prototype.format = function (token = "-", fillZero = true) { //author: meizz
    var d = this,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    while (fillZero && year.length < 4) 
        year = '0' + year;
    while (fillZero && month.length < 2) 
        month = '0' + month;
    while (fillZero && day.length < 2) 
        day = '0' + day;

    return [year, month, day].join(token);
}

String.prototype.DateFormat = function () {
    let dateSplit = this.split("/");
    if (this.includes("-")) {
        dateSplit = this.split("-");
    }
    
    function fillZero(str, count) {
        while (str.length < count) {
            str = "0" + str;
        }
        return str;
    }
    if (dateSplit.length == 3) {
        dateSplit[0] = fillZero(dateSplit[0], 4);
        dateSplit[1] = fillZero(dateSplit[1], 2);
        dateSplit[2] = fillZero(dateSplit[2], 2);
    }
    return dateSplit.join("/");
}

String.prototype.format = function (...args) {
    let str = this;
    args.forEach((arg, index) => {
        str = str.replace(new RegExp(`[{]${index}[}]`,"g"), arg);
    });
    return str;
}