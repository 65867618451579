<template>
  <div class="row mx-0">
    <div
      class="field"
      :style="{
        'text-align': 'left',
        width: labelWidth ? `${labelWidth}` : '0px',
      }"
    >
      <span :class="requiredClass" style="margin: auto 0px">{{ field }}</span>
    </div>
    <div
      :style="{
        margin: 'auto',
        width: `calc( 100% - ${labelWidth || '0px'} )`,
        display: 'flex',
      }"
    >
      <radio-button
        v-for="selection in selections"
        :key="selection.tag"
        class="py-0 px-2"
        :group="group"
        :tag="selection.tag"
        :text="selection.text"
        :noImg="true"
        :disabled="disabled"
        :value="value"
        @input="update($event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "field",
    "labelWidth",
    "value",
    "group",
    "selections",
    "disabled",
    "required",
  ],
  methods: {
    update(value) {
      if (this.value != value) {
        this.$emit("input", value);
      }
    },
  },
  mounted() {
    this.selections.forEach((selection) => {
      $(`#${this.group}${selection.tag}`)[0].checked =
        this.value == selection.tag;
    });
  },
  computed: {
    requiredClass() {
      return this.required ? "required" : "required beforeOpacity";
    },
  },
  watch: {
    value: function (v) {
      this.selections.forEach((selection) => {
        const isTarget = v == selection.tag;
        if (isTarget) {
          this.update(v);
        }
        $(`#${this.group}${selection.tag}`)[0].checked = isTarget;
      });
    },
  },
};
</script>
