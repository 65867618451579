<template>
  <div style="display: flex; align-items: center">
    <input
      :class="!noImg ? 'roundRadio' : ''"
      type="checkbox"
      :id="tag"
      :value="tag"
      @input="update($event.target)"
      :disabled="disabled"
    />
    <label
      :class="!noImg ? 'roundRadio' : ''"
      :for="tag"
      style="margin-bottom: 0"
      >{{ text }}</label
    >
  </div>
</template>
<script>
export default {
  props: ["text", "tag", "model", "value", "noImg", "disabled"],
  methods: {
    update(target) {
      if (target.checked) {
        this.value.push(target.value);
      } else {
        const index = this.value.indexOf(target.value);
        if (index > -1) {
          this.value.splice(index, 1);
        }
      }
      this.$emit("input", this.value);
    },
  },
  mounted() {
    $(`#${this.tag}`)[0].checked = this.value.indexOf(this.tag) >= 0;
  },
  watch: {
    value: function (v) {
      $(`#${this.tag}`)[0].checked = v.indexOf(this.tag) >= 0;
    },
  },
};
</script>
