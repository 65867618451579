<template>
  <div
    class="row colorWhite content bgTitle mx-0"
    style="position: relative; height: 50px; align-items: center"
  >
    <div
      style="position: absolute; width: 100%; text-align: center; display: flex"
    >
      <div class="title m-auto">
        {{ $t(title) }}
      </div>
    </div>
    <div class="pl-3" style="position: relative">
      <Icon
        v-if="menu"
        type="md-menu"
        @click="$emit('menuClick')"
        size="36"
        style="z-index: 1"
        class="button clickScale"
      />
      <Icon
        v-if="back"
        type="md-arrow-round-back"
        @click="$emit('backClick')"
        size="36"
        style="z-index: 1"
        class="button clickScale"
      />
    </div>
    <select
      v-model="lang"
      style="
        position: absolute;
        right: 20px;
        color: white;
        background: transparent;
        border: 0px;
      "
    >
      <option class="bgTitle" value="en">EN</option>
      <option class="bgTitle" value="tw">繁</option>
      <option class="bgTitle" value="cn">簡</option>
    </select>
  </div>
</template>
<script>
import i18n from "../../i18n/i18n";
import FieldSelect from "./FieldSelect.vue";
export default {
  components: { FieldSelect },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lang: i18n.locale,
    };
  },
  methods: {},
  mounted() {},
  watch: {
    lang(value) {
      i18n.switchLang(value);
    },
  },
};
</script>