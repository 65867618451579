<template>
  <div class="row mx-0 h-100">
    <div
      class="px-0 field h-100"
      :style="{
        'text-align': 'left',
        width: labelWidth,
      }"
    >
      {{ field }}
    </div>
    <div
      class="h-100"
      :style="{
        margin: 'auto',
        width: `calc( 100% - ${labelWidth} )`,
        'white-space': 'pre-wrap',
        display: 'flex',
        'justify-content': 'flex-end',
      }"
    >
      <select class="w-100 h-100" @change="update($event.target.value)">
        <option
          v-for="option in selections"
          :key="option.value"
          :value="option.value"
          :selected="option.value == value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    labelWidth: {
      type: String,
      default: "100px",
    },
    field: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    selections: {
      type: Array,
      default: [],
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
