<template>
 <!-- 服務條款頁面 -->
  <div
    class="p-3"
    style="margin: auto; text-align: left; white-space: pre-wrap"
  >
    {{ $t("services", [hostname]) }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      hostname: window.location.hostname,
    };
  },
};
</script>