<template>
  <div :class="'roundButton ' + buttonClass" style="width: 100%">
    <div style="display: flex; justify-content: center">
      <div style="width: 7vmin">
        <img
          :src="src"
          style="width: auto; height: 5vmin; margin: 1vmin; cursor: pointer"
        />
      </div>
      <div style="line-height: 7vmin">{{ $t("login_login") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["src", "buttonClass"],
  methods: {},
};
</script>
