<template>
  <div class="border-radius p-2">
    <div v-for="category in record.good_food" :key="category.class_id">
      <div class="colorBlack">{{ category.classification }}</div>
      <div style="display: flex; flex-flow: wrap">
        <div v-for="food in category.foods" :key="food.food_id">
          <louyi-tool-tip theme="light">
            <div
              slot="label"
              class="content colorMain button clickScale pr-2"
              style="text-decoration-line: underline; cursor: help"
            >
              {{ food.name }}
            </div>
            <div slot="content" style="width: 200px">
              <div
                class="colorGreen"
                style="white-space: normal; word-break: break-all"
              >
                {{ food.name }}
              </div>
              <Divider class="bgGreen my-2" />
              <div v-for="(content, index) in food.contents" :key="index">
                <div v-if="content.desc">
                  <div class="colorBlack">{{ content.title }}</div>
                  <div
                    style="white-space: normal; word-break: break-all"
                    v-html="getContent(content.desc)"
                  ></div>
                </div>
              </div>
            </div>
          </louyi-tool-tip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>