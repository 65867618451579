<template>
  <!-- 輸入邀請碼頁面 -->
  <div>
    <div class="py-5">
      <field-edit
        class="inputSolid"
        :field="$t('home_field_invite_code')"
        v-model="invite_code"
        :placeholder="$t('home_field_invite_code_hint')"
        :maxlength="10"
        labelWidth="80px"
      />
    </div>
    <div class="button1" @click="submit()">{{$t('common_button_commit')}}</div>
  </div>
</template>
<script>
import LoginApiHelper from "../../script/Utility/LoginApiHelper";
export default {
  mounted() {
    this.$root.$on("enter", this.submit);
  },
  beforeDestroy() {
    this.$root.$off("enter", this.submit);
  },
  data() {
    return {
      invite_code: "",
    };
  },
  methods: {
    submit() {
      if (this.invite_code) {
        loading();
        LoginApiHelper.checkInviteCode(this.invite_code)
          .then((result) => {
            loading(false);
            if (result.status) {
              this.$router.push({
                name: "Verify",
                params: { source: this.invite_code },
              });
            } else {
              alert(this.$t('home_field_invite_code_error'));
            }
          })
          .catch(errorHandler);
      } else {
        alert(this.$t('home_field_invite_code_hint'));
      }
    },
  },
};
</script>