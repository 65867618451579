<template>
  <div>
    <MazPhoneNumberInput
      size="sm"
      :showCodeOnList="true"
      :noFlags="true"
      :position="position"
      :defaultPhoneNumber="defaultPhoneNumber"
      :onlyCountries="onlyCountries"
      :ignoredCountries="ignoredCountries"
      :translations="{
        countrySelectorLabel: $t('phone_input_country_code'),
        countrySelectorError: $t('phone_input_country_error'),
        phoneNumberLabel: $t('phone_input_number_hint'),
        example: $t('phone_input_number_example') + ':',
      }"
      @update="update"
    />
  </div>
</template>
<script>
import { MazPhoneNumberInput } from "maz-ui";

export default {
  components: { MazPhoneNumberInput },
  props: {
    value: {
      type: Object,
      default() {
        return {
          mobile_country_code: "",
          mobile: "",
          success: false,
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    let onlyCountries = process?.env?.MIX_ALLOW_COUNTRY_CODES ?? "TW";
    let ignoredCountries = process?.env?.MIX_IGNORE_COUNTRY_CODES ?? null;
    if (onlyCountries == "ALL") {
      onlyCountries = null;
    } else {
      onlyCountries = onlyCountries.split(",");
    }
    if (ignoredCountries) {
      ignoredCountries = ignoredCountries.split(",");
    } else {
      ignoredCountries = [];
    }
    return {
      onlyCountries,
      ignoredCountries,
      defaultPhoneNumber: this.getPhoneNumber(),
      result: null,
    };
  },

  updated() {
    console.log("");
  },
  methods: {
    getClearMobile(number) {
      return number.replace(/[ -]/g, "");
    },
    getPhoneNumber() {
      let phoneNumber = this.defaultPhoneNumber;
      let isSame = false;
      if (this.result) {
        if (this.result.countryCallingCode) {
          isSame =
            this.result.countryCallingCode == this.value.mobile_country_code;
        }
        if (isSame && this.result.formatNational) {
          isSame =
            this.getClearMobile(this.result.formatNational) ==
            this.value.mobile;
        }
      }
      if (!isSame) {
        phoneNumber = this.value.mobile;
        if (this.value.mobile_country_code) {
          phoneNumber = `+${this.value.mobile_country_code}${this.value.mobile}`;
        }
      }
      return phoneNumber;
    },
    update(result) {
      this.result = result;
      if (this.defaultPhoneNumber.indexOf("+") != 0) {
        let mobile = result.formatNational || "";
        if (mobile) {
          mobile = this.getClearMobile(mobile);
        }
        this.$emit("input", {
          mobile_country_code: result.countryCallingCode || "",
          mobile: mobile,
          success: result.isValid,
        });
      } else {
        this.defaultPhoneNumber = this.getClearMobile(
          this.result.formatNational
        );
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.defaultPhoneNumber = this.getPhoneNumber();
      },
      deep: true,
    },
  },
};
</script>

