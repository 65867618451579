/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'bootstrap';
import '../css/app-d768.css';
import '../css/app-u768.css';
import '../css/app.css';
import './script/Common.js';
import router from './router';
import Vue from 'vue';
import store from "./store";
import ViewUI from 'view-design';
import VueWindowSize from 'vue-window-size';
import i18n from './i18n/i18n.js'
import 'view-design/dist/styles/iview.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'maz-ui/lib/css/base.css';
import 'maz-ui/lib/css/maz-phone-number-input.css';
import jQuery from 'jquery'

window.$ = window.jQuery = jQuery
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.use(VueWindowSize);
Vue.use(ViewUI);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    i18n,
    router,
    el: '#app',
    store
});