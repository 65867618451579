<template>
  <div class="border-radius p-2 colorMain">
    <div
      v-for="(row, index) in record.congenital_constitution"
      :key="index"
    >
      <DividerText v-if="index" :dash="true" />
      <label class="subTitle bold" v-html="row.title"> </label>
      <div v-for="(content, index) in row.content" :key="index">
        <div v-if="typeof content == 'string'" v-html="content"></div>
        <!-- 特例表單 -->
        <div v-else>
          <table
            border="1"
            style="text-align: center; white-space: break-spaces"
          >
            <tbody>
              <tr>
                <th v-html="content[0].title"></th>
                <th v-html="content[1].title"></th>
                <th v-html="content[2].title"></th>
                <th v-html="content[3].title"></th>
              </tr>
              <tr>
                <td
                  rowspan="3"
                  v-html="`${content[0].content[0]}\n${content[0].content[1]}`"
                ></td>
                <td rowspan="3" v-html="content[1].content[0]"></td>
                <td rowspan="3" v-html="content[2].content[0]"></td>
                <td rowspan="1" v-html="content[3].content[0]"></td>
              </tr>
              <tr>
                <th v-html="content[4].title"></th>
              </tr>
              <tr>
                <td v-html="content[4].content[0]"></td>
              </tr>
              <tr>
                <td v-html="content[0].content[2]"></td>
                <td v-html="content[1].content[1]"></td>
                <td v-html="content[2].content[1]"></td>
                <td v-html="content[4].content[1]"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {},
};
</script>
<style scoped>
th {
  background: #eee;
}
td {
  padding: 10px;
}
</style>