<template>
  <div class="row mx-0 w">
    <div
      class="px-0 field"
      :style="{
        width: labelWidth,
        display: 'flex',
      }"
    >
      <span :class="requiredClass" style="margin: auto 0px">{{ field }}</span>
    </div>
    <div
      class="px-0"
      :style="{
        margin: 'auto',
        'text-align': contentAlign,
        width: `calc( 100% - ${labelWidth} )`,
      }"
    >
      <PhoneNumberInput
        :value="value"
        @input="update($event)"
        style="flex-grow: 1"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contentAlign: {
      type: String,
      default: "right",
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    field: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default() {
        return {
          mobile_country_code: "",
          mobile: "",
          success: false,
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    password: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requiredClass() {
      return this.required ? "required" : " required beforeOpacity";
    },
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
