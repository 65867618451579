<template>
  <div class="row text-dark x-md-center subTitle" style="position: relative">
    <div
      class="col col-4 col-sm-4 col-md-3 col-lg-2 bgItem px-2"
      style="display: flex; min-height: 30vmin"
    >
      <img
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        style="margin: auto"
      />
    </div>
    <div class="col-8 align-self-center">
      <div style="text-align: left">
        {{ product.name }}
      </div>
      <div class="content colorGray" style="text-align: left; max-width: 90%">
        {{ product.content }}
      </div>

      <div
        class="colorDarkRed"
        style="
          text-align: left;
          padding-top: 3vmin;
          padding-left: 1vmin;
          font-weight: normal;
        "
      >
        <span class="priceDelete">${{ product.price + 99 }}</span> ${{
          product.price
        }}
      </div>
    </div>
    <div
      style="
        padding-right: 0;
        position: absolute;
        right: 1vmin;
        bottom: 12vmin;
        width: 9vmin;
      "
      @click="remove()"
    >
      <img src="/img/no.png" />
    </div>
    <div style="padding-right: 0; position: absolute; right: 0; bottom: 0">
      <field-count
        field=""
        v-model="product.count"
        labelWidth="15vmin"
        height="5vmin"
        style=""
      />
    </div>
  </div>
</template>
<script>
import FieldCount from "./FieldCount.vue";
import FieldValue from "./FieldValue.vue";
export default {
  components: { FieldCount, FieldValue },
  props: ["product"],
  methods: {
    remove() {
      confirm(this.$t("scitem_product_remove_confirm"), () => {
        this.$store.commit("remove", {
          id: this.product.id,
          selection: this.product.selection,
        });
      });
    },
  },
  watch: {
    "product.count": function (value) {
      if (value > 0) {
        this.$store.commit("cartModify", {
          id: this.product.id,
          count: value,
          selection: this.product.selection,
        });
      } else {
        confirm(
          this.$t("scitem_product_remove_confirm"),
          () => {
            this.$store.commit("remove", {
              id: this.product.id,
              selection: this.product.selection,
            });
          },
          () => {
            this.product.count = 1;
          }
        );
      }
    },
  },
};
</script>