<template>
  <div class="border-radius p-2">
    <div v-for="(tea, index) in record.drink.tea_set" :key="index">
      <LouyiToolTip theme="light">
        <div
          slot="label"
          class="content colorMain button clickScale pb-2"
          style="text-decoration-line: underline; cursor: help"
        >
          {{ tea.tea_name }}
        </div>
        <div slot="content" style="width: 200px">
          <div
            class="colorGreen"
            style="white-space: normal; word-break: break-all"
          >
            {{ tea.tea_name }}
          </div>
          <Divider class="bgGreen my-2" />
          <div v-for="(content, index) in tea.contents" :key="index">
            <div v-if="content.desc">
              <div class="colorBlack">{{ content.title }}</div>
              <div
                style="white-space: normal; word-break: break-all"
                v-html="getContent(content.desc)"
              ></div>
            </div>
          </div>
        </div>
      </LouyiToolTip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>