import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const loginApi = {
    "sendVerifyMobile": "/api/SendVerifyMobile",
    "verifyMobile":"/api/VerifyMobile",
    "checkInviteCode": "/api/CheckInviteCode",
    "checkActiveCode": "/api/CheckActiveCode",
}



export default class LoginApiHelper {
    public static sendVerifyMobile(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.sendVerifyMobile, params);
    }
    public static verifyMobile(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, loginApi.verifyMobile, params);
    }
    public static checkInviteCode(inviteCode: String) {
        return ApiHelper.callSelf(ApiMethods.POST, `${loginApi.checkInviteCode}/${inviteCode}`);
    }
    public static checkActiveCode(activeCode: String, source: String) {
        return ApiHelper.callSelf(ApiMethods.POST, `${loginApi.checkActiveCode}/${activeCode}`, {source});
    }
}