<template>
  <div class="row mx-0 text-dark x-md-center content">
    <div
      class="col col-4 col-sm-4 col-md-3 col-lg-2 bgItem px-2"
      style="display: flex; min-height: 30vmin"
    >
      <img
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        style="margin: auto"
      />
    </div>
    <div class="col-8 align-self-center">
      <div style="text-align: left">
        {{ product.name }}
      </div>
      <div class="mt-1 description colorGray" style="text-align: left">
        {{ product.content || "" }}
      </div>
      <div class="row pt-3 mx-0">
        <div
          class="subTitle colorDarkRed col-4 px-0"
          style="
            text-align: left;
            margin: auto;
            padding-left: 1vmin;
            font-weight: normal;
          "
        >
          $ {{ product.price }}
        </div>

        <field-value
          class="my-2 content colorTitle col-8"
          field=""
          :value="`x${product.count}`"
          labelWidth="15vmin"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldValue from "./FieldValue.vue";
export default {
  components: { FieldValue },
  props: ["product"],
  computed: {},
};
</script>