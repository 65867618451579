import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 自訂語言檔
import en from '../i18n/lang/en'
import cn from '../i18n/lang/cn'
import tw from '../i18n/lang/tw'

// 使用插件
Vue.use(VueI18n)


// 建立 VueI18n 實體
const i18n = new VueI18n({
  messages: { en, tw, cn }
})
/**
 * @description 切換網站語系
 */
i18n.switchLang = (newLang) => {
  if (newLang.indexOf('tw') >= 0 || newLang.indexOf('zh-hk') >=0 || newLang.indexOf('zh-mo') >=0) {
      newLang = 'tw';
  } else if(newLang.indexOf('zh') >= 0 || newLang.indexOf('cn') >= 0){
      newLang = 'cn';
  } else {
    newLang = 'en'
  }
  i18n.locale = newLang
  localStorage.setItem('locale', newLang)
}
var language = localStorage.getItem('locale') || window.navigator.userLanguage || window.navigator.language||window.navigator.browserLanguage||window.navigator.systemLanguage
language = language.toLowerCase()
i18n.switchLang(language);
window.i18n = i18n;
export default i18n