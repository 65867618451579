<template>
  <div>
    <div class="py-3">
      <div v-if="text" class="title colorTitle">{{ text }}</div>
      <div class="w-100 divider" :class="dash ? 'dash' : ''" style=""></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    dash: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.divider {
  border-bottom: 1px solid var(--color-drak-blue);
  &.dash {
    border-bottom: 1px dashed var(--color-drak-blue);
  }
}
</style>