<template>
  <div class="border-radius p-2 colorMain">
    <DividerText :text="record.most_sick.description" />
    <div v-for="(sick, index) in record.most_sick.diseaseList" :key="index">
      <DividerText v-if="index" :dash="true" />
      <label class="subTitle bold" v-html="sick.name"> </label>
      <div v-html="sick.desc"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>