<template>
  <div class="p-3 colorMain border-radius">
    <div class="bold" v-html="constitutionText"></div>
    <div v-if="showDetail">
      {{ record.constitution_detail.description }}

      <DividerText :dash="true" />
      <label class="subTitle bold">
        {{
          `${constitutionName}${$t(
            "result_subject_constitution_common_status"
          )}`
        }}
      </label>
      <div
        v-for="(symptoms, index) in record.constitution_detail.symptoms"
        :key="index"
      >
        {{ index + 1 }}.{{ symptoms.description }}
      </div>
      <DividerText :dash="true" />
      <label class="subTitle bold">
        {{ $t("result_subject_constitution_notice") }}
      </label>
      <div>
        {{ record.constitution_detail.notice }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    constitutionText() {
      if (this.record) {
        return this.$t("result_you_are_constitution", [
          `<label class='subTitle px-1 bold'>${this.constitutionName}</label>`,
        ]);
      } else {
        return "";
      }
    },
    constitutionName() {
      if (this.record) {
        if (this.record.constitution) return this.record.constitution;
        else if (this.record.constitution_detail)
          return this.record.constitution_detail.const_name;
      }
      return "";
    },
  },
};
</script>