var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0 w"},[_c('div',{staticClass:"px-0 field",style:({
      width: _vm.labelWidth,
      display: 'flex',
    })},[_c('span',{class:_vm.requiredClass,staticStyle:{"margin":"auto 0px"}},[_vm._v(_vm._s(_vm.field))])]),_vm._v(" "),_c('div',{staticClass:"px-0",style:({
      margin: 'auto',
      'text-align': _vm.contentAlign,
      width: ("calc( 100% - " + _vm.labelWidth + " )"),
    })},[_c('PhoneNumberInput',{staticStyle:{"flex-grow":"1"},attrs:{"value":_vm.value},on:{"input":function($event){return _vm.update($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }