<template>
  <div
    class="text-dark colorBlack content"
    style="
      height: 100%;
      overflow: hidden;
      border: #ebdccd solid;
      position: relative;
    "
  >
    <div style="height: 55vmin; align-items: center; display: flex">
      <img
        class="bgLight"
        style="width: 100%"
        v-lazy="product.imgs[0] ? product.imgs[0] : '/img/lady.png'"
        :alt="product.name"
        @click="
          $router.push({
            name: 'Product',
            params: { product: product, id: product.id },
          })
        "
      />
    </div>
    <div class="bgItem" style="padding: 1vmin; height: 100%">
      <div style="margin-bottom: 0.5vmin; text-align: left; height: 10vmin">
        {{ product.name }}
      </div>
      <div
        class="description colorGray"
        style="margin-bottom: 6vmin; text-align: left; height: 11vmin"
      >
        {{ product.content }}
      </div>
    </div>
    <div
      class="row mx-0"
      style="position: absolute; left: 1vmin; bottom: 2vmin; width: 100%"
    >
      <div class="content col-7 px-0 my-auto" style="text-align: left">
        {{ $t("product_price") }}
        <span class="priceDelete">${{ product.price + 99 }}</span
        ><span class="colorDarkRed">${{ product.price }}</span>
      </div>
      <div
        v-if="!product.selections.length"
        class="col-5"
        style="display: flex; flex-flow: column; margin: auto"
      >
        <img
          @click="addToCart()"
          src="/img/Product-car.png"
          style="width: 5vmin; margin-right: 0; margin-left: auto"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["product"],
  methods: {
    addToCart() {
      if (this.product.id) {
        this.$store.commit("add", { product: this.product, count: 1 });
        toast(
          this.$t("product_add_to_cart_success", [`${this.product.name}x1`])
        );
      }
    },
  },
};
</script>