<template>
  <div class="border-radius p-2 colorMain">
    <DividerText :text="record.second_sick.description" />
    <div v-for="(sick, index) in record.second_sick.diseaseList" :key="index">
      <LouyiToolTip theme="light">
        <div
          slot="label"
          class="content colorMain button clickScale pb-2"
          style="text-decoration-line: underline; cursor: help"
        >
          {{ sick.name }}
        </div>
        <div slot="content" style="width: 200px">
          <div
            style="white-space: normal; word-break: break-all"
            v-html="getContent(sick.desc)"
          ></div>
        </div>
      </LouyiToolTip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>